<template>
    <div class="page-order-detail">
        <TopBar :showBack="true" :title="item?'Заказ ' + item.znumber:'Загрузка заказа'"/>
        
        <div class="order-detail" v-if="item">
            <div class="mb-10px" style="font-size:20px">
                {{orderType}}
            </div>

            <div class="mb-10px" style="font-size:14px">
                {{'Дата/время заказа:    '+ item.time}}
            </div>

            <div class="mb-10px" style="font-size:14px" v-if="item.type!=='inrest'">
                {{'Дата/время в заказе: '+ orderTime}}
            </div>
        
            <div class="order-status" v-show="this.progressStep">
                <!-- если оплачен -->
                <div class="order-status--icon" :class="{'--active':this.progressStep>1}"> 
                    <i class="fas fa-coins"></i>
                </div>
                <span class="order-status--point">·</span>
                <!-- если взяли в работу -->
                <div class="order-status--icon" :class="{'--active':this.progressStep>2}">
                    <i class="far fa-hat-chef"></i>
                </div>
                <span class="order-status--point" v-show="item.type=='delivery'">·</span>
                <!-- если "доставка" и доставляется  -->
                <div class="order-status--icon" :class="{'--active':this.progressStep >3}"  v-show="item.type=='delivery'">
                    <i class="far fa-biking"></i>
                </div>
                <span class="order-status--point" v-show="item.type=='self'">·</span>
                <!-- если "самовывоз" и готов к самовывозу  -->
                <div class="order-status--icon" :class="{'--active':this.progressStep >3}"  v-show="item.type=='self'">
                    <i class="far fa-utensils"></i>
                </div>
                <span class="order-status--point">·</span>
                <!-- если готово  -->
                <div class="order-status--icon" :class="{'--active':this.progressStep>4}">
                    <i class="fas fa-utensils-alt" v-show="item.type=='inrest'"></i>
                    <i class="fas fa-box-check" v-show="item.type!='inrest'"></i>
                </div>
            </div>
            <div class="progress-text" v-show="this.progressText">
                {{this.progressText}}
            </div>

            <div class="mb-10px">
                <a class="btn btn-small" :href="item.receipt" v-show="item.receipt">Скачать чек</a>
            </div>

            <div class="fs-14 mb-10px">
                {{'Заведение: '+ item.name}}
            </div>
            <div class="fs-14 mb-10px">
                {{'Адрес заведения: '+ item.address}}
            </div>
            <div class="fs-14 mb-10px" v-if="addressDelivery">
                {{'Адрес доставки: '+ addressDelivery}}
            </div>
            <div class="fs-14 mb-10px" v-if="timeDelivery">
                {{'Время доставки: '+ timeDelivery}}
            </div>
            <div class="text-bold mb-10px">
                {{'Состав заказа: '}}
            </div>
            

            <div v-for="basketItem in item.basket" :key="basketItem.id" class="basket-item">
                <img :src="basketItem.photo" style="margin-right:15px" class="basket-item-img"/>
                <div class="basket-item-info">
                    <div class="basket-item-name">
                        {{basketItem.name + ' x'+basketItem.cnt}}
                    </div>
                    <div class="basket-item-spec" v-if="basketItem.spec.id && !basketItem.specs.length">
                        {{basketItem.spec.name + ' - ' + priceFormat(basketItem.spec.price)+ ' р.'}}
                    </div>
                    <div class="basket-item-spec" v-for="(spec, specIndex) in basketItem.specs" :key="specIndex">
                        {{spec.name + ' - ' + priceFormat(spec.price)+ ' р. x'+spec.cnt}}
                    </div>
                    <div class="basket-item-comment" v-if="basketItem.comment">
                        {{basketItem.comment}}
                    </div>
                    <div class="btn btn-small mt-10px" @click="rateItem(basketItem)" v-if="item.paid==1 && basketItem.rate==false">
                        Оценить блюдо
                    </div>
                </div>
                <div class="basket-item-price">
                    <div v-if="item.discountPercent" class="basket-item-price-old">
                        {{priceFormat(basketItem.price*basketItem.cnt)+' р.'}}
                    </div>
                    <div>
                        {{priceFormat(basketItem.price*basketItem.cnt * (100-item.discountPercent) / 100)+' р.'}}
                    </div>
                </div>
            </div>
            <div class="mb-15px" v-show="item.comment">
                {{item.comment}}
            </div>

            <div v-if="item && item.paid==1 && !item.rate" style="text-align:center;font-size:18px;color:#6C84A0">
                Оцените заказ:
            </div>
            <StarRating v-if="item && item.paid==1 && !item.rate" v-model="order_rating" class="star-rating"/>

            <!-- <div class="btn btn-primary" @click="onRepeat" v-if="item.paid=='0' && item.status >= 50">Повторить</div> -->
            <div class="btn btn-primary" @click="onPayment" v-if="item.paid=='0' && item.status < 50">Оплатить</div>

            <div v-show="item.paid && item.tips_id">
                <div style="text-align:center;font-size:18px;color:#6C84A0;">
                    Оставить чаевые:
                </div>
                <div class="button-tips">

                </div>
                <ButtonTips v-for="(tip,index) in $store.state.settings.tips_volume" :key="index" :sum="tip.sum+' руб - '" :text="tip.text" @click="tapTips(tip.sum)" />
            </div>

            <div v-if="item && item.paid==1 && !item.rate" class="btn btn-primary" @click="rateOrder()" style="margin:10px 0 0">
                Оцените заказ
            </div>

            <a class="btn btn-primary mt-10px" href="/" >
                На главную
            </a>
        </div>

        <ModalOrderRating :visible="modalOrderRatingVisible" @hide="modalOrderRatingVisible = false" :order="item"  v-if="item" :order_rating="order_rating" @input="onModalOrderRatingInput" />

        <ModalRating :visible="modalRatingVisible" @hide="modalRatingVisible = false" :order="item" v-if="item && modalBasketItem" :item="modalBasketItem" @input="onModalRatingInput" />

        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import StarRating from '../components/StarRating.vue';
import ModalOrderRating from '../components/ModalOrderRating.vue';
import ModalRating from '../components/ModalRating.vue';
import ButtonTips from '../components/ButtonTips.vue';

export default {
    components: {
        TopBar, 
        StarRating, 
        ModalOrderRating, 
        ModalRating,
        ButtonTips
    },
    props: ['rate'],
    data(){
        return {
            item: null,
            proccessing: false,

            props: {},
            order_rating: 5,
            order_comment: '',
            
            progressStep: 0,
            progressText: '',
            modalOrderRatingVisible: false,
            modalRatingVisible: false,
            modalBasketItem: null
        }
    },
    created(){
        this.loadOrder();
    },
    computed: {
        orderTime : function (){
            
            
            if(this.item.as_ready){
                return "По готовности";
            }
            if(this.item.props.length>0){
                const props =  JSON.parse(this.item.props);
                return props.deliveryTime?props.deliveryTime:"По готовности";
            }else{
                return "По готовности"; 
            }
            
        },
        orderType(){
            var r = '';
            switch(this.item.type)
            {
                case 'inrest':
                    r = 'Заказ в заведении';
                    break;
                case 'self':
                    r =  'Заказ самовывозом';
                    break;
                case 'delivery':
                    r = 'Заказ на доставку';
                    break;
            }
            return r;
        },
        timeDelivery(){
            // console.log('timeDelivery-start')
            if(!this.props.deliveryTime) 
                return false;
            // console.log('timeDelivery-start-2')

            return this.props.deliveryTime;
            // console.log('timeDelivery-end')
        },
        addressDelivery(){
            // console.log('addressDelivery-start',this.props)
            if(!this.props.address) 
                return false;
            // console.log('addressDelivery-start-2')
            
            const address = this.props.address;
            let result = '';
            if(address.city)
                result += address.city;

            if(address.street)
                result += ', ' + address.street;

            if(address.house)
                result += ', ' + address.house;
            
            if(address.podjezd)
                result += ', под.' + address.podjezd;
            
            if(address.domofon)
                result += ', домофон ' + address.domofon;
            
            if(address.floor)
                result += ', эт.' + address.floor;
            
            if(address.room)
                result += ', кв.' + address.room;
            // console.log('addressDelivery-end')
            return result;
        }
    },
    methods: {
        tapTips(sum){
            window.location.href = 'https://pay.cloudtips.ru/p/'+this.item.tips_id+'/?amount='+sum;
        },
        onPayment(){
            this.$router.push({name:'Payment', params: {
                orderId: this.item.id,
                orderNum: this.item.znumber,
                orderSum: this.item.sum*1
            }});
        },
        onRepeat(){

        },
        rateOrder(){
            this.modalOrderRatingVisible = true;
        },
        onModalOrderRatingInput(r){
            if(r.success == true)
            {
                this.item.rate = r;
            }
            this.modalOrderRatingVisible = false;
        },
        rateItem(basketItem)
        {
            this.modalBasketItem = basketItem;
            this.modalRatingVisible = true;
        },
        onModalRatingInput(r){
            if(r.success == true)
            {
                this.modalBasketItem.rate = r;
            }
            this.modalRatingVisible = false;
        },
        loadOrder(){
            if(this.proccessing) return;

            this.proccessing = true;
            
            fetch(this.$serverUrl + '/api/v1/order/listUser/'+this.$store.state.user.id,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: this.$store.state.user.id,
                    user_token: this.$store.state.user.password,
                    id: this.$route.params.id
                })
            })
            .then(r=>r.json())
            .then(r=>{
                this.item = r[0];
                /**
                 * данные для прогресс-бара
                 */
                this.progressStep = this.item['progressBarData']['step'];
                this.progressText = this.item['progressBarData']['text'];
                const key = this.$store.state.restorans.findIndex(item => item.fs_uid == this.item.restoran_id);
                if(key >= 0){
                    this.$store.commit('setRestoran', this.$store.state.restorans[key])
                }
                
                if(this.rate){
                    this.rateOrder();
                }
                this.proccessing = false;
            }).catch(()=>{
                alert(this.$error502)
                this.proccessing = false;
            });
        }
    }
}
</script>

<style scoped>
.order-detail {
    padding: 20px 10px 20px;
}

.progress-text {
    text-align: center;
    font-size:18px;
    font-weight:bold;
    margin-bottom:5px;
}

.order-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    margin: 0 0 5px 0;
}

.order-status--icon {
    width: 40px;
    height: 40px;

    font-size: 20px;
    border-radius: 20px;
    text-align: center;
}

.order-status--icon i {
    line-height: 40px;
}


.order-status--point
{
    font-size: 30px;
    margin: 0 5px;
}

.order-status--icon.--active
{
    background-color: #333;
    color:#fff;
    margin: 0 5px;
}

.basket-item {
    margin-bottom:15px;
    padding-bottom:15px;
    border-bottom: 1px solid #C1D0E1;
    display: flex;
    flex-direction: row;
}

.basket-item-spec {
    font-size:14px;
    margin-top:10px;
}

.basket-item-comment {
    font-size:14px;
    margin-top:10px; 
}

.basket-item-price-old {
    text-decoration:line-through;
    font-size:12px;
    color:#d50000;
}

.basket-item-img {
    width: 70px;
}

.basket-item-info {
    flex-grow: 1;
}

.basket-item-price{
    white-space: nowrap;
    text-align: right;
}

.star-rating {
    margin: 5px 0 15px;
    justify-content: center;
}
</style>